import colors from 'constants/colors';

export type DashboardIconProps = {
  size?: number;
  color?: string;
};

export const DashboardIcon = ({
  size = 16,
  color = colors.gray500,
}: DashboardIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7188_741)">
      <path
        d="M13.3333 2.55835H2.66667C2.29848 2.55835 2 2.85683 2 3.22502V6.55835C2 6.92654 2.29848 7.22502 2.66667 7.22502H13.3333C13.7015 7.22502 14 6.92654 14 6.55835V3.22502C14 2.85683 13.7015 2.55835 13.3333 2.55835Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.33333 9.89168H2.66667C2.29848 9.89168 2 10.1902 2 10.5583V13.8917C2 14.2599 2.29848 14.5583 2.66667 14.5583H7.33333C7.70152 14.5583 8 14.2599 8 13.8917V10.5583C8 10.1902 7.70152 9.89168 7.33333 9.89168Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3332 9.89168H11.3332C10.965 9.89168 10.6665 10.1902 10.6665 10.5583V13.8917C10.6665 14.2599 10.965 14.5583 11.3332 14.5583H13.3332C13.7014 14.5583 13.9998 14.2599 13.9998 13.8917V10.5583C13.9998 10.1902 13.7014 9.89168 13.3332 9.89168Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7188_741">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.55835)"
        />
      </clipPath>
    </defs>
  </svg>
);
