import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import colors from 'constants/colors';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  background-color: ${colors.offWhite};
  padding: 20px 20px;
  border-radius: 5px;
  border: 1px solid ${colors.gray200};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  height: 100%;
`;

export const TextContainer = styled.div<{
  backgroundColor?: string;
  isClickable?: boolean;
}>`
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.offWhite};
  padding: 20px 20px;
  border-radius: 5px;
  border: 1px solid ${colors.gray200};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  align-items: flex-start;
  justify-content: center;

  ${(props) =>
    props.isClickable &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${colors.white};
      transition: background-color 0.1s ease-in-out;
    }
    `}
`;

export const TextContent = styled.div`
  display: inline-flex;
  flex: 0;
  flex-direction: column;
  align-items: flex-start;
`;

export const Header = styled.div<{
  columnView?: boolean;
  alignStart?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => (props.columnView ? 'column' : 'row')};
  align-items: ${(props) => (props.alignStart ? 'flex-start' : 'center')};
  margin-bottom: 2px;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    margin-bottom: 16px;
    padding-top: 0;
  }
`;

export const Left = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    width: 100%;
    align-items: center;
    margin-bottom: 12px;
  }
`;

export const Right = styled.div<{ alignStart?: boolean }>`
  align-items: ${(props) => (props.alignStart ? 'flex-start' : 'center')};
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  flex-direction: row;
  display: flex;
  line-height: 18px;
  gap: 8px;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.gray600};
`;

export const Watermark = styled.div`
  visibility: hidden;
  position: absolute;
  bottom: 12px;
  right: 24px;
  z-index: 998;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 5px;
`;

const pulse = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

export const CustomSpinner = styled.div<{ alternate?: boolean }>`
  width: 40px;
  height: 40px;
  background-color: ${(props) =>
    props.alternate ? colors.cyan400 : colors.blue};

  border-radius: 100%;
  animation: ${pulse} 1s infinite ease-in-out;
`;

export const InfoIconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 18px;

  span {
    display: flex;
    padding: 10px 0;
  }
`;
