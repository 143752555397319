import colors from 'constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: end;
  height: 20px;
  gap: 2px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

export const Value = styled.div`
  color: ${colors.gray500};
`;

export const ValueDescription = styled.div`
  color: ${colors.gray850};
  font-weight: 600;
`;
