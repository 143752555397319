import { NameCell } from 'modules/analytics/components/common/table';
import { Button, CircularSpinner, ExportIcon } from 'modules/common-ui';
import { Table } from 'modules/tableV7';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import type { CellProps, Column } from 'react-table';

interface AssetsTableProps<ItemType extends object> {
  metricColumn: Column<ItemType>;
  loading: boolean;
  page: string;
  rows: Array<ItemType>;
  since: Date;
  until: Date;
  title?: string;
  exportLoading?: boolean;
  onExportClick?: () => void;
  sortMetricDesc?: boolean;
}

const buildNameCol = <
  ItemType extends {
    name: string | null;
    make: string | null;
    assetId: number;
  },
>(
  name: string,
  since: Date,
  until: Date,
) => {
  const extraUrlParams = {
    since: moment(since).format('YYYY-MM-DD'),
    until: moment(until).format('YYYY-MM-DD'),
  };
  return {
    Header: name,
    id: 'name',
    maxWidth: 275,
    Cell: ({ row: { original } }: CellProps<ItemType>) => {
      return (
        <NameCell
          name={original.name}
          make={original.make}
          assetId={original.assetId}
          openLinkInNewWindow
          extraUrlParams={extraUrlParams}
        />
      );
    },
  };
};

export const AssetsTable = <
  ItemType extends {
    name: string | null;
    make: string | null;
    assetId: number;
  },
>({
  metricColumn,
  loading,
  page,
  rows,
  since,
  until,
  title,
  exportLoading,
  onExportClick,
  sortMetricDesc = false,
}: AssetsTableProps<ItemType>) => {
  const { t } = useTranslation('analytics');
  const tableId = `${page}_assetsListTable`;

  const columns = [
    buildNameCol<ItemType>(
      t('reports.common.assetListTable.columns.name'),
      since,
      until,
    ),
    metricColumn,
  ];
  const defaultVisibleColumns = columns.map((col) => col.id);

  const exportButton = (
    <Button key="exportAssetListBtn" onClick={onExportClick}>
      {exportLoading ? <CircularSpinner /> : <ExportIcon size={16} />}
    </Button>
  );

  const actions =
    typeof onExportClick === 'function' ? [exportButton] : undefined;

  const defaultSorted = [
    {
      id: metricColumn.id,
      desc: sortMetricDesc,
    },
  ];

  return (
    <Table
      title={title || t('reports.common.assetsUsed')}
      id={tableId}
      trackingProps={{
        page: { page },
        section: 'assetsList',
      }}
      columns={columns}
      data={rows}
      defaultVisibleColumns={defaultVisibleColumns}
      loading={loading}
      defaultPageSize={25}
      rowHeight={48}
      displaySearch
      searchPlaceholder={t('reports.common.searchAssets', {
        count: rows.length,
      })}
      displayColumnToggler={false}
      disableResizing
      saveColumnsWidth={false}
      saveVisibleColumns={false}
      defaultSorted={defaultSorted}
      searchFullWidth
      actions={actions}
    />
  );
};
