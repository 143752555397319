import { Drawer } from '@mui/material';
import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import colors from 'constants/colors';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from '../Button';
import { DoubleChevronRightIcon, DownArrow } from '../Icons';
import {
  ActionRow,
  Content,
  Footer,
  SideDrawerActions,
  SideDrawerControls,
  Title,
} from './SideDrawer.css';

export type SideDrawerProps = {
  open: boolean;
  title: string | null;
  onClose: () => void;
  controls?: {
    onPrev: () => void;
    onNext: () => void;
    disablePrev: boolean;
    disableNext: boolean;
  };
  children: React.ReactNode;
  actions?: React.ReactNode;
  footerButton?: React.ReactNode;
};

export const SideDrawer = ({
  open,
  title,
  onClose,
  controls,
  children,
  actions,
  footerButton,
}: SideDrawerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_START });

  const [isSticky, setIsSticky] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = (event: Event) => {
      const drawerExternalDiv = event.srcElement as HTMLDivElement;
      setIsSticky(drawerExternalDiv.scrollTop > 0);
    };

    const drawerParentNode = ref.current?.parentNode as HTMLDivElement;

    if (open && ref.current) {
      ref.current.focus();

      drawerParentNode.addEventListener('scroll', handleScroll, true);
    }

    return () =>
      drawerParentNode.removeEventListener('scroll', handleScroll, true);
  }, [open]);

  const handleKeys = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
    if (event.key === 'ArrowDown' && controls && !controls.disableNext) {
      controls.onNext();
    }
    if (event.key === 'ArrowUp' && controls && !controls.disablePrev) {
      controls.onPrev();
    }
  };

  return (
    <Drawer
      tabIndex={0}
      ref={ref}
      onKeyDown={handleKeys}
      variant="persistent"
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        sx: {
          backgroundColor: colors.offWhite,
          boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 6px 0px',
          width: isMobile ? '100%' : '40%',
          marginTop: isMobile ? 0 : '8px',
          marginBottom: isMobile ? 0 : '8px',
          borderRadius: '5px 0 0 5px',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          stroke: colors.neutral200,
          height: isMobile ? '100%' : 'calc(100% - 16px)',
          boxSizing: 'border-box',
        },
      }}
    >
      <ActionRow isSticky={isSticky}>
        <SideDrawerControls>
          <Button onClick={onClose} style={{ padding: '5px' }}>
            <DoubleChevronRightIcon color={colors.gray850} />
          </Button>
          {controls && (
            <>
              <Button
                onClick={controls.onPrev}
                disabled={controls.disablePrev}
                style={{ rotate: '180deg' }}
              >
                <DownArrow />
              </Button>
              <Button onClick={controls.onNext} disabled={controls.disableNext}>
                <DownArrow />
              </Button>
            </>
          )}
        </SideDrawerControls>
        {Boolean(actions) && <SideDrawerActions>{actions}</SideDrawerActions>}
      </ActionRow>
      <Content>
        {Boolean(title) && <Title>{title}</Title>}
        {children}
        {Boolean(footerButton) && <Footer>{footerButton}</Footer>}
      </Content>
    </Drawer>
  );
};
