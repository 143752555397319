import {
  LineChart,
  type LineChartData,
} from 'modules/analytics/components/Dataviz/LineChart/LineChart';
import { SimpleValue } from 'modules/analytics/components/Dataviz/SimpleValue/SimpleValue';
import { Card } from 'modules/analytics/components/common/layout/Card';
import type { DailyIdleRate } from 'modules/analytics/reports/idleRate/gql';
import { Button } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { PeriodScale } from 'utils/time';
import { AssetsCount } from '../common/AssetsCount';

type IdleRateComponentProps = {
  data: LineChartData[];
  average: {
    value: number | null;
    assetsCount: number;
    activeAssetsCount: number;
  };
  previousPeriodAverage: {
    value: number | null;
    assetsCount: number;
    activeAssetsCount: number;
  };
  setSelectedDataPoint: (period: DailyIdleRate) => void;
  setGranularityScale: (scale: PeriodScale) => void;
};

export const IdleRateComponent = ({
  average,
  data,
  previousPeriodAverage,
  setSelectedDataPoint,
  setGranularityScale,
}: IdleRateComponentProps) => {
  const { t } = useTranslation('newHome');
  const navigate = useNavigate();

  return (
    <Card
      title={t('analytics.kpi.idleRate.title')}
      actionElement={
        <>
          <Button
            onClick={() => {
              setGranularityScale('week');
              setSelectedDataPoint({
                activeAssetsCount: average.activeAssetsCount,
                value: average.value || 0,
                assetsCount: average.assetsCount,
                date: new Date().toISOString(),
              });
            }}
          >
            {t('analytics.kpi.common.viewAssets')}
          </Button>
          <Button onClick={() => navigate('/analytics/idle-rate')}>
            {t('analytics.kpi.common.analyze')}
          </Button>
        </>
      }
      disableImageExport
    >
      <SimpleValue
        value={average.value}
        unit={'%'}
        comparativeValue={previousPeriodAverage.value}
        orientation="row"
        hideDescription
      />
      <AssetsCount>
        {t('analytics.kpi.common.assetsCount', {
          count: average.assetsCount,
        })}
      </AssetsCount>
      <LineChart
        data={data}
        unit={'%'}
        tooltipV2
        periodScale="day"
        displayBars
        onItemClicked={(item: DailyIdleRate) => {
          setGranularityScale('day');
          setSelectedDataPoint({
            ...item,
            value: item.value * 100,
          });
        }}
      />
    </Card>
  );
};
