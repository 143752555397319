import colors from 'constants/colors';
import styled from 'styled-components';

export const AssetsCount = styled.div`
  color: ${colors.gray500};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 24px;
`;
