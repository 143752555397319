import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { type Unit, displayValueAndUnit } from 'utils/unit';
import { NoValue } from './NoValue';
import { NoTrend } from './Trend/NoTrend';
import { TrendDescription } from './Trend/TrendDescription';
import { Container, InnerContent, Value } from './styles.css';

export type SimpleValueProps = {
  value: number | null;
  comparativeValue?: number | null;
  loading?: boolean;
  tooltip?: JSX.Element | string;
  unit: Unit;
  reverseColor?: boolean;
  hideNoTrend?: boolean;
  hideDescription?: boolean;
  orientation?: 'column' | 'row';
};

const Loader = ({ hideNoTrend }: { hideNoTrend: boolean }) => {
  return (
    <Container>
      <ContentLoader
        width="100%"
        height={hideNoTrend ? 30 : 60}
        speed={2}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        title="Loading"
      >
        <rect x="9" y="6" rx="4" ry="4" width="100" height="20" />
        {!hideNoTrend && (
          <rect x="9" y="40" rx="4" ry="4" width="120" height="13" />
        )}
      </ContentLoader>
    </Container>
  );
};

export const SimpleValue = ({
  value,
  comparativeValue,
  loading = false,
  unit,
  reverseColor = false,
  hideNoTrend = false,
  hideDescription = false,
  orientation = 'column',
}: SimpleValueProps) => {
  const { t } = useTranslation('analytics');

  if (loading) {
    return <Loader hideNoTrend={hideNoTrend} />;
  }

  const hasValue = value !== null;

  return (
    <Container>
      {hasValue ? (
        <InnerContent orientation={orientation}>
          <Value>{displayValueAndUnit(value, unit, t)}</Value>
          {comparativeValue != null ? (
            <TrendDescription
              value={value - comparativeValue}
              unit={unit === '%' ? 'pts' : unit}
              reverseColor={reverseColor}
              hideDescription={hideDescription}
            />
          ) : (
            !hideNoTrend && <NoTrend />
          )}
        </InnerContent>
      ) : (
        <NoValue orientation={orientation} />
      )}
    </Container>
  );
};
