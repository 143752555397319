import { StickyHeaderLayout } from 'modules/layout/sticky-header/StickyHeader.layout';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { Store } from 'reducers';
import { BodyContent } from './components/BodyContent';

export const Home = () => {
  const user = useSelector((state: Store) => state.user);

  const { t } = useTranslation('newHome');

  return (
    <StickyHeaderLayout
      title={t('header.welcome', { firstname: user.firstName })}
      isNew
      actionContent={null}
      subheaderContent={undefined}
      displaySubheader={true}
      bodyContent={<BodyContent />}
      fullWidth={false}
      noBottomPadding
    />
  );
};
