import { useQuery } from '@apollo/client';
import { useDatePickerRef } from 'context/analytics/DatePickerRefContext';
import type { TFunction } from 'i18next';
import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import { MultiChartCard } from 'modules/analytics/components/Cards/MultiChartCard';
import { DescriptionContainer } from 'modules/analytics/components/Cards/MultiChartCard/styles.css';
import type { LineChartData } from 'modules/analytics/components/Dataviz/LineChart/LineChart';
import { SimpleValueDescription } from 'modules/analytics/components/Dataviz/SimpleValue/SimpleValueDescription';
import type { TrackingProperties } from 'modules/analytics/components/common/AssetListDrawer';
import type { PeriodScaleOption } from 'modules/analytics/components/common/PeriodScaleButtons/PeriodScaleButtons';
import { SelectedPeriod } from 'modules/analytics/components/common/SelectedPeriod/SelectedPeriod';
import { TooltipContent } from 'modules/analytics/components/common/Tooltip/Tooltip';
import { fillEmptyData } from 'modules/analytics/lib/fillEmptyData';
import { Bold } from 'modules/common-ui';
import moment from 'moment';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type PeriodScale,
  formatDateShort,
  formatPreviousPeriod,
  getDateShortFormat,
  getEndPeriodDate,
  getUTCEndOfDayDate,
  getUTCStartOfDayDate,
} from 'utils/time';
import { displayValueAndUnit } from 'utils/unit';
import {
  type DailyIdleGqlResponse,
  type DailyIdleRate,
  type IdleRateReport,
  dailyIdleRateGqlQuery,
} from '../../../gql';
import { convertFactorToRoundedPercent } from '../../../lib/utils';
import { Period, PeriodContainer, PeriodTitle } from '../../../styles.css';
import { EvolutionDrawer } from './EvolutionDrawer';

type IdleRateAnalysisCardProps = {
  assetFilters: AssetFilterGQL;
  since: Date;
  until: Date;
};

type EvolutionData = {
  data: LineChartData[];
  average: {
    value: number | null;
    assetsCount: number;
    activeAssetsCount: number;
  };
  previousPeriodAverage: {
    value: number | null;
    assetsCount: number;
    activeAssetsCount: number;
  };
};

export const parseChartData = (
  period: { since: Date; until: Date },
  periodScale: PeriodScale,
  idleReportDailyIdleRates: DailyIdleRate[] | undefined,
  idleRateReportLastDays: IdleRateReport | undefined,
  t: TFunction<'analytics', undefined>,
): EvolutionData => {
  const res: EvolutionData = {
    data: [],
    average: { value: null, assetsCount: 0, activeAssetsCount: 0 },
    previousPeriodAverage: {
      value: null,
      assetsCount: 0,
      activeAssetsCount: 0,
    },
  };
  if (idleReportDailyIdleRates) {
    res.data = fillEmptyData(
      idleReportDailyIdleRates.map((item) => {
        const value = convertFactorToRoundedPercent(item.value);
        return {
          value,
          date: new Date(+item.date),
          tooltipData: [
            {
              label: t('reports.idleRate.overview.chart.tooltip.idleRate'),
              value: `<b>${displayValueAndUnit(value, '%', t)}</b>`,
            },
            {
              label: t('reports.idleRate.overview.chart.tooltip.usedAssets'),
              value: `<b>${item.assetsCount}</b>/${item.activeAssetsCount}`,
            },
          ],
          originalData: item,
        };
      }),
      period,
      periodScale,
    );
  }
  if (idleRateReportLastDays) {
    if (idleRateReportLastDays.current?.value) {
      res.average = {
        value: Math.round(idleRateReportLastDays.current.value * 10000) / 100,
        assetsCount: idleRateReportLastDays.current.assetsCount,
        activeAssetsCount: idleRateReportLastDays.current.activeAssetsCount,
      };
    }
    if (idleRateReportLastDays.previous?.value) {
      res.previousPeriodAverage = {
        value: Math.round(idleRateReportLastDays.previous.value * 10000) / 100,
        assetsCount: idleRateReportLastDays.previous.assetsCount,
        activeAssetsCount: idleRateReportLastDays.previous.activeAssetsCount,
      };
    }
  }
  return res;
};

const granularityInDays: Record<string, number> = {
  day: 1,
  week: 7,
  month: 30,
};

const isGranularityRelevant = (
  granularity: PeriodScale,
  dataSince: Date,
  dataUntil: Date,
): boolean => {
  const diffInDays = Math.round(
    (dataUntil.getTime() - dataSince.getTime()) / (1000 * 3600 * 24),
  );
  return diffInDays > (granularityInDays[granularity] || 0);
};

const drawerTrackingProps: TrackingProperties = {
  page: 'idleRate',
  section: 'analysis',
  source: 'evolutionChart',
};

export const EvolutionCard = ({
  assetFilters,
  since,
  until,
}: IdleRateAnalysisCardProps) => {
  const { t } = useTranslation('analytics');
  const [selectedItem, setSelectedItem] = useState<DailyIdleRate | null>(null);
  const datePickerRef = useDatePickerRef();
  const granularityScaleOptions = useMemo(() => {
    const options: PeriodScaleOption[] = [
      {
        text: t('reports.idleRate.analysis.evolution.daily'),
        value: 'day',
      },
      {
        text: t('reports.idleRate.analysis.evolution.weekly'),
        value: 'week',
      },
      {
        text: t('reports.idleRate.analysis.evolution.monthly'),
        value: 'month',
      },
    ];
    // check if we need to disable any granularity scale
    for (const option of options) {
      if (isGranularityRelevant(option.value, since, until)) {
        continue;
      }
      option.disabled = true;
      option.tooltip = t('periodScale.unrelevantScale');
    }
    return options;
  }, [since, until, t]);

  const [granularityScale, setGranularityScale] = useState(
    granularityScaleOptions[0].value,
  );

  // update granularity scale if it is not relevant with new dates
  useEffect(() => {
    if (isGranularityRelevant(granularityScale, since, until)) return;
    const newGranularityScale = [...granularityScaleOptions]
      .reverse()
      .find((option) => !option.disabled)?.value;
    if (!newGranularityScale) return;
    setGranularityScale(newGranularityScale);
  }, [since, until, granularityScale, granularityScaleOptions]);

  const { loading, data } = useQuery<DailyIdleGqlResponse>(
    dailyIdleRateGqlQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !since || !until,
      variables: {
        assetFilters: assetFilters,
        since: getUTCStartOfDayDate(since),
        until: getUTCEndOfDayDate(until),
        periodScale: granularityScale.toUpperCase(),
      },
    },
  );

  const parsedData = useMemo(() => {
    return parseChartData(
      { since, until },
      granularityScale,
      data?.viewer?.idleReportDailyIdleRates,
      data?.viewer?.idleRateReportLastDays,
      t,
    );
  }, [data, since, until, granularityScale, t]);

  const selectedPeriodComponent = (
    <SelectedPeriod since={since} until={until} datePickerRef={datePickerRef} />
  );

  const assetListPeriod = useMemo(() => {
    if (!selectedItem) return null;
    const since = new Date(+selectedItem.date);
    return {
      since,
      until: getEndPeriodDate(since, granularityScale),
    };
  }, [selectedItem, granularityScale]);

  return (
    <Fragment>
      <MultiChartCard
        trackingProps={{
          page: 'idleRate',
          section: 'evolution',
        }}
        title={t(
          `reports.idleRate.analysis.evolution.title.${granularityScale}`,
        )}
        data={parsedData.data}
        average={parsedData.average.value}
        loading={loading}
        scaleOptions={granularityScaleOptions}
        onScaleChange={setGranularityScale}
        yAxisTitle={t('reports.idleRate.analysis.evolution.yAxisTitle')}
        averageAxisTitle={t(
          'reports.idleRate.analysis.evolution.averageAxisTitle',
        )}
        selectedScale={granularityScale}
        unit={'%'}
        selectedPeriodComponent={selectedPeriodComponent}
        description={
          <DescriptionContainer>
            <PeriodContainer>
              <PeriodTitle>
                {t('reports.idleRate.analysis.periodIdleRate')}
              </PeriodTitle>
              <Period>{` ${moment(since).format('ddd Do MMM')} - ${moment(
                until,
              ).format('ddd Do MMM')}`}</Period>
            </PeriodContainer>
            <SimpleValueDescription
              value={parsedData.average.value}
              comparativeValue={parsedData.previousPeriodAverage.value}
              loading={loading}
              unit={'%'}
              tooltip={
                <TooltipContent
                  label={t('reports.idleRate.title')}
                  currentPeriodLabel={`${formatDateShort(
                    moment(since),
                  )} - ${formatDateShort(moment(until))}`}
                  previousPeriodLabel={formatPreviousPeriod(
                    since,
                    until,
                    getDateShortFormat(),
                  )}
                  currentValue={parsedData.average.value}
                  currentValueDetails={
                    parsedData.average.value ? (
                      <>
                        <Bold>{parsedData.average.assetsCount}</Bold>/
                        {parsedData.average.activeAssetsCount}
                      </>
                    ) : undefined
                  }
                  previousValue={parsedData.previousPeriodAverage.value}
                  previousValueDetails={
                    parsedData.previousPeriodAverage.value ? (
                      <>
                        <Bold>
                          {parsedData.previousPeriodAverage.assetsCount}
                        </Bold>
                        /{parsedData.previousPeriodAverage.activeAssetsCount}
                      </>
                    ) : undefined
                  }
                  unit="%"
                  hideDrawerClickAction
                />
              }
            />
          </DescriptionContainer>
        }
        onItemClicked={(data: DailyIdleRate) => {
          setSelectedItem(data);
        }}
        tooltipV2
      />
      <EvolutionDrawer
        activeAssetsCount={selectedItem?.activeAssetsCount}
        usedAssetsCount={selectedItem?.assetsCount}
        assetFilters={assetFilters}
        averageIdleRate={
          selectedItem?.value != null ? selectedItem.value * 100 : null
        }
        granularityScale={granularityScale}
        selectedPeriod={assetListPeriod}
        open={!!selectedItem}
        onClose={() => {
          setSelectedItem(null);
        }}
        trackingProps={drawerTrackingProps}
      />
    </Fragment>
  );
};
