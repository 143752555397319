import type { TFunction } from 'i18next';
import { unit } from 'mathjs';
import i18n from '../i18n';
import { format } from './number';
import { formatAsHoursMinutes } from './time';

export type UnitType = 'Metric' | 'Imperial';
export const USER_UNIT = 'hiboo__unit';

type MetricUnits =
  | 'L'
  | 'km'
  | 't'
  | 'L/h'
  | 'L/100km'
  | 'kg'
  | 'kg/h'
  | 'kg/100km';

// We don't include units related to new analytics reports for now
// so they will not be converted to imperial units (to be updated later on)
const metricToImperial: { [k in MetricUnits]?: string } = {
  L: 'gal',
  km: 'mi',
  t: 'ton',
  'L/h': 'gal/h',
  'L/100km': 'mpg',
};

const getThousandSeparator = () =>
  i18n.t('translation:common.thousandSeparator');

const getDecimalSeparator = () => i18n.t('translation:common.decimalSeparator');

// eslint-disable-next-line max-statements
export const convertUnitAsString = (
  value: number | null | undefined,
  origin: MetricUnits | null,
  precision: number,
  displayUnit: boolean | undefined = true,
): string => {
  const serializedUserUnit = localStorage.getItem(USER_UNIT);
  const userUnit = serializedUserUnit;

  const thousandSeparator = getThousandSeparator();
  const decimalSeparator = getDecimalSeparator();

  let res: string;
  if (value == null) return '';
  if (userUnit === 'Imperial' && origin && metricToImperial[origin]) {
    let convertedUnit = '';

    switch (origin) {
      case 'L/100km':
        convertedUnit = `${Math.round((235.214583 / value) * 100) / 100}`;
        break;
      default:
        const conversionUnit = metricToImperial[origin];
        if (conversionUnit) {
          convertedUnit = unit(value, origin)
            .to(conversionUnit)
            .toNumber()
            .toFixed(precision);
        } else {
          convertedUnit = value.toFixed(precision);
        }
    }

    res = `${format(convertedUnit, thousandSeparator)}`;

    if (displayUnit) {
      res += ` ${metricToImperial[origin]}`;
    }
  } else {
    res = `${format(
      value.toFixed(precision),
      thousandSeparator,
      decimalSeparator,
    )}`;
    if (displayUnit) {
      res += ` ${origin}`;
    }
  }
  return `${res}`;
};

export const convertPascal = (
  value: number,
  _unit: string,
  precision: number,
): number => {
  return +unit(value, 'Pa').to(_unit).toNumber().toFixed(precision);
};

export const convertKgToTon = (weight?: number): number | undefined => {
  if (weight === undefined) return undefined;
  return +(weight / 1000).toFixed(2);
};

export type Unit =
  | '%'
  | 'pts'
  | 'L/h'
  | 'L/100km'
  | 't'
  | 'kg'
  | 'kg/h'
  | 'kg/100km'
  | 'L'
  | 'h'
  | 'd'
  | 'days'
  | 'km'
  | 'assets'
  | 'V'
  | '';

// eslint-disable-next-line complexity
export const displayValueAndUnit = (
  value: number | string,
  unit: Unit | '',
  t: TFunction<'analytics', undefined>,
  precision: 'int' | 'float' = 'float',
  round = true,
) => {
  const thousandSeparator = getThousandSeparator();
  const decimalSeparator = getDecimalSeparator();

  switch (unit) {
    case '%':
      return precision === 'int'
        ? t('analytics:reports.percentageInt', { value })
        : t('analytics:reports.percentage', { value });
    case 'pts':
      return t('reports.points', { value });
    case 'L/h':
    case 'L/100km':
    case 'L':
      return convertUnitAsString(
        +value,
        unit,
        precision === 'int' ? 0 : 1,
        true,
      );
    case 't':
    case 'kg':
    case 'kg/100km':
    case 'kg/h':
    case 'km':
      // we don't use convertUnitAsString because `t` is converted to `ton`
      // and we don't want that for now
      return `${format(
        precision === 'int'
          ? Math.floor(Number(value))
          : round
            ? Number(Number(value).toFixed(1))
            : Number(value),
        thousandSeparator,
        decimalSeparator,
      )} ${unit}`;

    case 'h':
      // formatAsHoursMinutes can't do its job on negative value
      return (
        (Number(value) < 0 ? '-' : '') +
        formatAsHoursMinutes(Math.abs(Number(value)))
      );

    case 'd':
    case 'days':
      return t(`reports.common.${unit}`, { value });
    case 'assets':
      return `${value} ${t(`reports.common.${unit}`)}`;
    case '':
      return format(
        precision === 'int'
          ? Math.floor(Number(value))
          : round
            ? Number(Number(value).toFixed(1))
            : Number(value),
        thousandSeparator,
        decimalSeparator,
      );
    default:
      return `${value} ${unit}`;
  }
};
