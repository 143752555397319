import { BREAKPOINT_MOBILE_END } from 'constants/breakpoints';
import { AssetFilter } from 'modules/analytics/components/AssetFilter/AssetFilter';
import type { AssetFilterOptions } from 'modules/analytics/components/AssetFilter/types';
import { Section } from 'modules/analytics/components/common/layout/Section/Section';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CarbonEmissions, UsageHours } from './kpiViewer';
import { HourlyConsumption } from './kpiViewer/HourlyConsumption';
import { IdleRate } from './kpiViewer/IdleRate';
import { getLast7DaysPeriod } from './kpiViewer/utils/date';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;

  @media (max-width: ${BREAKPOINT_MOBILE_END}px) {
    flex-direction: column;
  }
`;

const CardContainer = styled.div`
  flex: 1 1 calc(50% - 2px); /* Two items per row with gap adjustment */
  max-width: calc(50% - 2px);
  height: 405px;

  @media (max-width: ${BREAKPOINT_MOBILE_END}px) {
    flex: 1 1 100%; /* Full width for smaller screens */
    max-width: 100%;
  }
`;

export const AnalyticsSection = () => {
  const { t } = useTranslation('newHome');
  const [appliedFilters, setAppliedFilters] = useState<AssetFilterOptions>({});
  const { since, until } = useMemo(() => getLast7DaysPeriod(), []);

  return (
    <Section
      title={t('analytics.last7days')}
      subtitle={t('analytics.subtitle')}
      actionElement={
        <AssetFilter
          appliedFilters={appliedFilters}
          onFilter={(filters) => {
            setAppliedFilters(filters);
          }}
          trackingProps={{ page: 'new-home' }}
          alignRight
        />
      }
    >
      <Container>
        <CardContainer>
          <UsageHours
            assetFilters={appliedFilters}
            since={since}
            until={until}
          />
        </CardContainer>
        <CardContainer>
          <IdleRate assetFilters={appliedFilters} since={since} until={until} />
        </CardContainer>
        <CardContainer>
          <HourlyConsumption
            assetFilters={appliedFilters}
            since={since}
            until={until}
          />
        </CardContainer>
        <CardContainer>
          <CarbonEmissions
            assetFilters={appliedFilters}
            since={since}
            until={until}
          />
        </CardContainer>
      </Container>
    </Section>
  );
};
